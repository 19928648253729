/* src/components/TermsPage.css */

.terms-page {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .page-header {
    text-align: center;
    padding: 20px 0;
  }
  
  .page-header h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .terms-content {
    font-size: 1.125rem;
    line-height: 1.8;
    color: #555;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .terms-content p {
    margin-bottom: 15px;
  }
  
  .terms-content p:last-child {
    margin-bottom: 0;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  
    .page-header h2 {
      font-size: 2rem;
    }
  
    .terms-content {
      padding: 15px;
    }
  
    .terms-content p {
      font-size: 1rem;
    }
  }
  