/* src/components/SerotoninSoundwaves.css */

.serotonin-soundwaves-page {
    font-family: 'Lato', sans-serif;
    color: #fff;
  }
  
  .event-title {
    font-size: 3rem;
    font-weight: bold;
    animation: fadeInDown 2s ease;
  }
  
  .event-description {
    padding: 60px 20px;
    background: #111;
    text-align: center;
  }
  
  .event-description h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ff0050;
  }
  
  .event-description p {
    font-size: 1.25rem;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .upcoming-event {
    padding: 60px 20px;
    background: #222;
    text-align: center;
  }
  
  .upcoming-event h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #ff7e00;
  }
  
  .event-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .event-image img {
    width: 80%;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .event-details {
    max-width: 600px;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.6;
    color: black;
  }
  
  .event-details h3 {
    font-size: 1.75rem;
    margin-bottom: 20px;
    color: #ff7e00;
  }
  
  .cta-button {
    background-color: #ff0050;
    color: #fff;
    padding: 15px 30px;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.25rem;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #ff7e00;
  }
  
  @media (min-width: 768px) {
    .event-content {
      flex-direction: row;
    }
  
    .event-image img {
      width: 50%;
      margin-right: 40px;
    }
  
    .event-details {
      text-align: left;
    }
  }
  