/* src/components/EventsPage.css */

.events-page {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .page-header {
    padding: 40px 20px;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .page-header h2 {
    font-size: 2.5rem;
    color: #333;
    margin: 0;
  }
  
  /* Description Section */
  .events-description {
    padding: 40px 20px;
    text-align: center;
    font-size: 1.125rem;
    color: #555;
  }
  
  /* Call to Action Section */
  .call-to-action {
    padding: 40px 20px;
    text-align: center;
    font-size: 1.5rem;
    color: #000;
  }
  
  /* Event Section */
  .event-section {
    padding: 40px 20px;
  }
  
  .event-content {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .event-content.reverse {
    flex-direction: row-reverse;
  }
  
  .event-image {
    flex: 1;
  }
  
  .event-image img {
    width: 100%;
    border-radius: 8px;
  }
  
  .event-description {
    flex: 1;
    font-size: 1.125rem;
    color: #555;
  }
  
  .event-description p {
    margin-bottom: 20px;
  }
  
  .find-out-more-button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .find-out-more-button:hover {
    background-color: #333;
  }
  