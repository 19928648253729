/* src/components/AboutPage.css */

.about-page {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .page-header {
    padding: 40px 20px;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .page-header h2 {
    font-size: 2.5rem;
    color: #333;
    margin: 0;
  }
  
  .brand-description {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.8;
    color: #555;
    text-align: center;
  }
  
  .brand-description p {
    margin-bottom: 20px;
    font-size: 1.125rem;
  }
  
  .brand-description strong {
    color: #333;
  }
  
/* Thought Process Section */
.thought-process-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.thought-process-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.thought-process-description {
  flex: 1;
}

.thought-process-description h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.thought-process-description p {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #555;
}

.thought-process-video {
  width: 50%;
  max-width: 100%;
}

@media (max-width: 768px) {
  .thought-process-content {
    flex-direction: column;
  }

  .thought-process-video {
    width: 100%;
  }
}
  