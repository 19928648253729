/* src/components/ContactPage.css */

.contact-page {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .page-header {
    padding: 40px 20px;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .page-header h2 {
    font-size: 2.5rem;
    color: #333;
    margin: 0;
  }
  
  /* Contact Information Section */
  .contact-info {
    padding: 40px 20px;
    text-align: center;
    font-size: 1.125rem;
    color: #555;
  }
  
  .contact-info a {
    color: #000;
    text-decoration: none;
    font-weight: bold;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  /* Inquiry Form Section */
  .inquiry-form {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .inquiry-form form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  .submit-button {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-start;
  }
  
  .submit-button:hover {
    background-color: #333;
  }
  
  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
    }
  }

  /* Error Message */
.error-message {
    color: red;
    margin-top: -10px;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }
  
  /* Success Modal */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 80%;
  }
  
  .modal h3 {
    margin-bottom: 10px;
  }
  
  .modal p {
    margin-bottom: 20px;
  }
  
  .modal-close-button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-close-button:hover {
    background-color: #333;
  }
  
  