/* src/components/Header.css */

/* General Styles */
.header {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Slim Announcement Bar */
.announcement-bar-container {
  margin: 0;
  padding: 0;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.announcement-bar {
  background-color: black;
  color: white;
  padding: 0;
  box-shadow: none;
  min-height: 20px;
}

.announcement-toolbar {
  padding: 0;
  min-height: 20px;
  justify-content: center;
}

.announcement-text {
  text-align: center;
  width: 100%;
}

/* Social Media Bar */
.social-media-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 20px;
  background-color: white;
  margin-top: 5px;
  position: relative;
  top: 20px;
  z-index: 999;
}

.social-media-bar .MuiIconButton-root {
  color: black;
  margin-left: 10px;
}

/* Logo Styling */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.website-logo {
  max-width: 100%;
  height: auto;
  max-height: 100px;
  object-fit: contain;
}

@media (max-width: 600px) {
  .website-logo {
    max-width: 70%;
    max-height: 80px;
  }
}

/* Navigation Styling */
.navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  position: sticky; /* Make the navigation sticky */
  top: 0; /* Stick to the top of the page */
  z-index: 998; /* Ensure it's above other content but below the announcement bar */
}

.desktop-menu {
  display: flex;
  gap: 20px; /* Spacing between menu items */
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.MuiButton-root {
  color: black;
}

.MuiMenu-paper {
  background-color: white;
}

@media (max-width: 960px) {
  .desktop-menu {
    display: none; /* Hide desktop menu on mobile */
  }

  .mobile-menu {
    display: flex; /* Show mobile menu on mobile */
  }
}
