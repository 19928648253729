.footer {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  padding: 20px 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align the content */
}

/* Navigation Section */
.footer-nav {
  text-align: center; /* Center the navigation */
}

.footer-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center; /* Center align the nav items */
  gap: 20px; /* Space between nav items */
}

.footer-nav ul li a {
  color: #fff; /* White links */
  text-decoration: none;
  font-size: 0.875rem;
}

.footer-nav ul li a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Copyright Section */
.footer-copyright {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #555; /* Optional: a subtle top border */
  font-size: 0.875rem;
  color: #ccc; /* Lighter gray color */
  text-align: center;
}
