/* src/components/ReWearRevolution.css */

.rewear-revolution-page {
  font-family: 'Lato', sans-serif;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.page-header {
  text-align: center;
  padding: 40px 0;
}

.page-header h2 {
  font-size: 3rem;
  font-weight: bold;
  animation: fadeInDown 2s ease;
}

/* New Hero Image Section */
.hero-image {
  width: 100%;
  margin-bottom: 40px;
}

.hero-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.event-description {
  padding: 60px 20px;
  background: #f5f5f5;
  text-align: center;
}

.event-description h3 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.event-description p {
  font-size: 1.25rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
}

.upcoming-event {
  padding: 60px 20px;
  background: #e0e0e0;
  text-align: center;
}

.upcoming-event h3 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.event-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-image img {
  width: 80%;
  border-radius: 8px;
  margin-bottom: 20px;
}

.event-details {
  max-width: 600px;
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.6;
}

.event-details h4 {
  font-size: 1.75rem;
  margin-bottom: 20px;
  color: #333;
}

.cta-button {
  background-color: #333;
  color: #fff;
  padding: 15px 30px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #555;
}

@media (min-width: 768px) {
  .event-content {
    flex-direction: row;
  }

  .event-image img {
    width: 50%;
    margin-right: 40px;
  }

  .event-details {
    text-align: left;
  }
}