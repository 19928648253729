/* src/components/HomePage.css */

.home-page {
  width: 100%;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.spotify-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.spotify-embed {
  width: 50%;
  max-width: 100%;
}

.about-section {
  padding: 40px 20px 60px;
  background-color: #f5f5f5;
  text-align: center;
}

.about-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.about-section p {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}

.cta-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 40px 20px;
}

.cta-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #000000; /* Changed to black */
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #333333; /* Slightly lighter black for hover effect */
}